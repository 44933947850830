<template>
  <div class="product-container">
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      custom-class="quanshiDialog"
      :append-to-body="true"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <el-card class="box-card">
        <p style="width: 100%;padding: 50px 0; text-align: center;">{{msg}}</p>
      </el-card>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import indexApi from "@/api";
export default {
  name: "Quanshi",
  data(){
    return {
      fullscreenLoading: false,
      centerDialogVisible: false,
      show: false,
      msg: '您的信息注册失败，请联系相关代表'
    }
  },
  created() {
    this.msg = '您的信息注册失败，请联系相关代表'
    let that = this;
    let _getToken = localStorage.getItem("token");
    let callBackUrl = that.$route.query.callbackUrl;
    const loading = this.$loading({
      lock: true,
      text: '正在处理，请稍后',
      spinner: 'el-icon-loading',
      background: 'rgba(0,0,0,.5)',
      customClass: "quanshiLoading",
    });
    if (_getToken){
      let params = {Authorization: _getToken};
      let _params = {type: 0};
      Promise.all([indexApi.getQuanshiUserInfo(params),indexApi.getUserInfo(_params)]).then(([quanShiInfo, info]) =>{
        console.log("quanShiInfo, info",quanShiInfo, info);
        let {state, isAppeal} = info.data;
        switch (state){
          case "valid":
          case "temporary":
            if (state == "temporary" && isAppeal == 1){
              info.data.userInfo = { name: info.data.name, avatar: info.data.avatar };
              localStorage.setItem('fakeLogin',true);
              localStorage.setItem('reallyLogin',0);
              localStorage.setItem('goSupplement',1);
              console.log('token========', localStorage.getItem('token'));
              clearInterval(that.$options.timer);
              that.$options.timer = null;
              setTimeout(function() {
                that.$router.push({ name: 'login',query:{callBackUrl}, params: { needAppeal: true, data: res.data } });
              }, 100);
              return;
            }
            let {appId, userData, ts, sign} = quanShiInfo.success?quanShiInfo.data : {};
            let currentLink = ''
            if(callBackUrl.indexOf('?')!=-1){
              currentLink = `${callBackUrl}&appId=${appId}&userData=${userData}&ts=${ts}&sign=${sign}`;
            }else{
              currentLink = `${callBackUrl}?appId=${appId}&userData=${userData}&ts=${ts}&sign=${sign}`;
            }
            console.log(appId,userData,ts,sign);
            console.log("currentLink",currentLink)
            localStorage.setItem('fakeLogin',true);
            loading.close();
            location.replace(currentLink);
            break;
          case "checked":
            this.msg = '您的信息正在验证中，暂时无法参会'
            loading.close();
            that.centerDialogVisible = true;
          break;
          case "invalid":
            console.log('申诉')
            loading.close();
            sessionStorage.setItem('needAppeal',true)
            // that.$stroage.setLocalItem('token', '');
            // localStorage.setItem('fakeLogin',true);
            // localStorage.setItem("loginEdNeedApple",false);
            const {href} = this.$router.resolve({name: "login"});
            let loginPath = `${href}?callBackUrl=${callBackUrl}`;
            setTimeout(function (){
              location.replace(loginPath);
            },500)
          break;
          default:
            loading.close();
            that.centerDialogVisible = true;
            break;
        }
      })
    } else {
      that.$message.error("您还未登录，请先进行登录注册！");
      const {href} = this.$router.resolve({name: "login"});
      let loginPath = `${href}?callBackUrl=${callBackUrl}`;
      console.log("loginPath",loginPath)
      setTimeout(function (){
        location.replace(loginPath);
      },3000)
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.product-container {
  background-image: url('~./images/bg.jpg');
  background-size: cover;
  line-height: 1;
  position: relative;
}
</style>
